
/* ----------------------------------------------------------

 Base Layout

---------------------------------------------------------- */

@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?i8fcpd');
  src:  url('../fonts/icomoon.eot?i8fcpd#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?i8fcpd') format('truetype'),
    url('../fonts/icomoon.woff?i8fcpd') format('woff'),
    url('../fonts/icomoon.svg?i8fcpd#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-list:before {
  content: "\e906";
}
.icon-access:before {
  content: "\e900";
}
.icon-facebook:before {
  content: "\e901";
}
.icon-heart:before {
  content: "\e902";
}
.icon-instagram:before {
  content: "\e903";
}
.icon-tel:before {
  content: "\e904";
}
.icon-time:before {
  content: "\e905";
}


body {
	color:#151515;
	overflow-x : hidden;
  font-family: 'fot-tsukuardgothic-std',sans-serif;
  font-weight: 700;
  letter-spacing: .4px;
  line-height: 1.6;
  font-size: 13px;
  -webkit-text-size-adjust: none;
	text-size-adjust: none;
}

body {
  -webkit-animation: 1s ease 0s normal opening;
  animation: 1s ease 0s normal opening;
}

@-webkit-keyframes opening {
  0% {opacity: 0;}
  30% {opacity: 0;}
  100% {opacity: 1;}
}
@keyframes opening {
  0% {opacity: 0;}
  30% {opacity: 0;}
  100% {opacity: 1;}
}

.en{
  font-family: "cantoni-pro",'fot-tsukuardgothic-std',sans-serif;
  font-weight: 400;
  line-height: 1.0;
  letter-spacing: 0;
}

a{
  color: inherit;
	text-decoration:none;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

a:link,
a:visited {
}

a:hover,
a:active{
  opacity:0.8;
}

::selection,
::-moz-selection {
background: #efd5d5;
}

a:hover img {
	opacity:0.8;
}

*:focus {
  outline: none;
}

#topcontrol {
	z-index:100;
}

#MenuNavi {
	display:none;
}

* {
	box-sizing: border-box;
}

mark {
  background: #ffffc0;
}

.section_cmn{
  padding: 70px 0;
  text-align: center;
}


/* ----------------------------------------------------------
 * Selected Text
 *---------------------------------------------------------- */
 ::selection {
  background: #f8eded; /* Safari */
}

::-moz-selection {
 background:#f8eded; /* Firefox */
}

/*  btn
---------------------------------------------------------- */

.btn{
	width:216px;
	height:42px;
	margin-left:auto;
	margin-right:auto;
}

.btn a{
	text-align:center;
	text-decoration:none;
	color: #FFF;
	position:relative;
  display:block;
  font-size: 14px;
	line-height:42px;
	transition: all .4s;
  -webkit-transition: all .4s;
  background:#daa4a3;
}

.btn a:hover{
}

.btn02{
	width:216px;
	height:42px;
	margin-left:auto;
	margin-right:auto;
	text-align:center;
	text-decoration:none;
	color: #FFF;
	position:relative;
  display:block;
  font-size: 14px;
	line-height:42px;
	transition: all .4s;
  -webkit-transition: all .4s;
  background:#daa4a3;
}


@media screen and (max-width: 760px) {

.btn{
	width:80%;
}

}


/*  wrapInner
---------------------------------------------------------- */

#wrapInner {
	width:100%;
}

#container {
	width:1000px;
	margin:0 auto;
	padding:0 0 35px;
}


/*  header
---------------------------------------------------------- */

.header {
  width: 100%;
  height: 70px;
  z-index: 99;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  min-width: 1050px;
  margin: 0 auto;
  position: relative;
}

.header a{
  text-decoration: none;
}

.header h1{
  width: 186px;
  height: 186px;
  position: absolute;
  top: -62px;
  left: 50%;
  transform: translateX(-50%);
  z-index: 999;
}

.header h1 a{
  display: block;
  height: 100%;
  background: url(../img/cmn/logo.png) no-repeat;
  background-size: 186px auto;
  text-indent: 105%;
  overflow: hidden;
  white-space: nowrap;
}

.header .gnav{
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.header .gnav li{
  display: inline-block;
}

.header .gnav li:nth-child(4){
  margin-right: 180px;
}

.header .gnav li a{
  font-size: 14px;
  padding:21px 10px 21px;
  text-align:center;
  display:block;
  position: relative;
  transition: 0.3s;
  -webkit-transition: 0.3s;
}

.header .gnav li a:hover{
  color: #daa4a3;
}
.header .gnav li.cv{
  width: 174px;
  height: 40px;
  margin-left: 32px;
}

.header .gnav li.cv a{
  display: block;
  width: 100%;
  height: 100%;
  background: #daa4a3;
  padding: 0;
  color: #FFF;
  font-size: 16px;
  line-height: 40px;
}

.header .gnav li.cv a:hover{
  color: #FFF;
}


@media screen and (max-width: 760px) {
img{
	width:100%;
}

.header {
  width: 100%;
  height: auto;
  min-width: 100%;
}

.header h1{
  width: 112px;
  height: 112px;
  top: -56px;
}

.header h1 a{
  background-size: 112px auto;
}

}

/*  mv_naka
 *---------------------------------------------------------- */

.mv_naka {
   width: 100%;
   height: 250px;
   background: #faf1f1 url(../img/cmn/mv.jpg) top center no-repeat;
}

body.news .mv_naka {
  background-image: url(../news/img/mv.jpg);
}

body.voice .mv_naka {
  background-image: url(../voice/img/mv.jpg);
}

body.facility .mv_naka {
  background-image: url(../facility/img/mv.jpg);
}

body.reserve .mv_naka {
  background-image: url(../reserve/img/mv.jpg);
}

@media screen and (max-width: 760px) {

.mv_naka {
   height: 180px;
   background-size: cover;
   background-image: url(../img/cmn/mv_sp.jpg);
}

body.news .mv_naka {
  background-image: url(../news/img/mv_sp.jpg);
}

body.voice .mv_naka {
  background-image: url(../voice/img/mv_sp.jpg);
}

body.facility .mv_naka {
  background-image: url(../facility/img/mv_sp.jpg);
}

body.reserve .mv_naka {
  background-image: url(../reserve/img/mv_sp.jpg);
}


}


/*  h2
 *---------------------------------------------------------- */
.article_h2 {
  text-align: center;
  padding: 80px 0 40px;
}

.article_h2 h2 {
  font-size: 14px;
}

.article_h2 h2 span {
  font-size: 70px;
  display: block;
  line-height: .8;
}

body.cmn .article_h2 h2 span {
  line-height: 1.2;
}

body.news .article_h2 h2 span {
  color: #9dbed6;
}

body.voice .article_h2 h2 span {
  color: #e6b186;
  line-height: .5;
}

body.facility .article_h2 h2 span {
  color: #b0b0d1;
}

body.reserve .article_h2 h2 span {
  color: #daa4a3;
}

@media screen and (max-width: 760px) {
.article_h2 {
  text-align: center;
  padding: 20% 0 3%;
}

.article_h2 h2 {
  font-size: 14px;
}

.article_h2 h2 span {
  font-size: 60px;
}

}

/*  footer
---------------------------------------------------------- */

.footer{
	width:100%;
	text-align:center;
}

.footer .footer_block{
  background:#faf1f1;
  padding: 88px 0 70px;
  margin-top: -100px;
}

.footer .flogo {
  width: 186px;
  margin: 0 auto 10px;
}

.footer .flogo img {
  width: 100%;
}

.footer .footer_block .tit {
  font-size: 14px;
}

.footer .footer_block .btn {
  width: 348px;
  height: 62px;
  margin: 20px auto 30px;
}

.footer .footer_block .btn a {
  font-size: 20px;
  line-height: 62px;
}

.footer .footer_block .sitemap_link {
  margin-bottom: 40px;
}

.footer .footer_block .sitemap_link li {
  display: inline-block;
  vertical-align: middle;
  margin: 0 12px;
}

.footer .footer_block .sitemap_link li:last-child {
  font-size: 20px;
}

.footer .footer_block .sitemap_link li::before {
  content: "- ";
}

.footer .footer_block .sitemap_link li:last-child::before {
  content: "";
}

.footer .footer_block .shop_list {
}

.footer .footer_block .shop_list .box {
  width: 190px;
  margin: 0 5px;
  display: inline-block;
  line-height: 1.4;
}

.footer .footer_block .shop_list .box .name {
  background: #FFF;
  color: #daa4a3;
  line-height: 50px;
  height: 50px;
  margin-bottom: 10px;
}

.footer .footer_block .shop_list .box .name.col02 {
  line-height: 1.4;
  padding: 8px 0;
}

.footer .footer_block .shop_list .box .name {
  background: #FFF;
  color: #daa4a3;
  line-height: 50px;
  height: 50px;
}

.footer .footer_block .shop_list .box .txt {
  font-size: 12px;
}

.footer .footer_block .shop_list .box .txt a {
  color: #daa4a3;
}

.footer .footer_block .shop_list .box .tel {
  font-size: 18px;
  margin: 10px 0 5px;
}

.footer .footer_block .shop_list .box .tel small{
  font-size: 13px;
}

.footer .footer_block .other_link {
  margin: 46px auto 0;
  width: 1200px;
}

.footer .footer_block .other_link .inner {
  border-top: 1px solid #a2a49e;
  border-bottom: 1px solid #a2a49e;
  padding:28px 0 24px;
  text-align: center;
  margin-top: -16px;
}

.footer .footer_block .other_link h4 {
  display: inline-block;
  padding: 0 20px;
  background: #faf1f1;
  font-size: 16px;
}

.footer .footer_block .other_link ul {
  display: inline-block;
  vertical-align: top;
  margin: 0 20px;
  text-align: left;
  font-size: 13px;
}

.footer .footer_block .other_link ul li::before {
  content: "- ";
}


.footer .copy{
  font-weight: normal;
  color:#595959;
  font-size: 12px;
  padding: 10px 0;
  background: #FFF;
}

.fix_cv {
  position: fixed;
	bottom: 30px;
	right: 30px;
  width: 172px;
  z-index: 999;
  transition: .4s;
  transform: translate3d(0, 0, 0);
}

body.reserve .fix_cv {
  display: none;
}


.fix_cv a {
  opacity: 0;
}

.fix_cv a.display {
  opacity: 1;
}

.fix_cv img {
	width: 100%;
}

@media screen and (max-width: 760px) {

.footer .footer_block{
  padding: 18% 5%;
  margin-top: -68px;
}

.footer .flogo {
  width: 112px;
  margin: 0 auto 5%;
}

.footer .footer_block .tit {
  font-size: 12px;
}

.footer .footer_block .btn {
  width: 90%;
  height: 50px;
  margin: 5% auto 10%;
}

.footer .footer_block .btn a {
  font-size: 18px;
  line-height: 50px;
}

.footer .footer_block .shop_list {
}

.footer .footer_block .shop_list .box {
  width: 100%;
  margin: 0 0 10%;
  display: block;
}

.footer .footer_block .shop_list .box .name {
  line-height: 1.0;
  height: auto;
  padding: 15px 0;
  margin-bottom: 4%;
}

.footer .footer_block .shop_list .box .name.col02 {
  line-height: 1.0;
  padding: 15px 0;
}

.footer .footer_block .shop_list .box .tel {
  font-size: 22px;
  margin: 3% 0 2%;
}

.footer .footer_block .shop_list .box .tel small{
  font-size: 13px;
}

.footer .footer_block .other_link {
  margin: 10% auto 0;
  width: 100%;
}

.footer .footer_block .other_link .inner {
  padding:8% 0 6%;
  margin-top: -12px;
}

.footer .footer_block .other_link h4 {
  padding: 0 15px;
  font-size: 15px;
}

.footer .footer_block .other_link ul {
  width: 80%;
}

.footer .footer_block .other_link ul a {
  padding: 5px 0;
}

.fix_cv {
	bottom: 2%;
	right: 2%;
  width: 30%;
}

}


/*  Pan
 *---------------------------------------------------------- */
 .pan .inner{
 	width:980px;
 	margin:0 auto;
 	font-size:11px;
	padding:10px 0 0;
	position:relative;
	z-index:2;
 }
 .pan .inner a{
 	text-decoration:underline;
	color:#000;
 }
 .pan a:hover{
	 color:#EC0000;
 }

/*  Main
---------------------------------------------------------- */
.container {
	width:100%;
}

.contents {
	width:980px;
	margin:0 auto 20px;
}
.container h2 {
	width:100%;
	height:197px;
	text-indent:-9999px;
}


/*  Table
---------------------------------------------------------- */
.tbl01 {
	width:100%;
	font-size:100%;
  text-align: left;
  background-size: cover;
}

.tbl01 th {
	background: #ebebf3;
  border-bottom: 1px solid #FFF;
	padding:20px;
	font-weight:normal;
	width:23%;
}

.tbl01 td {
	background: rgba(248,248,248,.8);
  border-bottom: 1px solid #FFF;
	padding:20px;
	word-break: break-all;
  line-height: 2.0;
}

.tbl02 {
	width:100%;
}

.tbl02 th {
	background:#faf0d0;
	padding:10px 15px;
	text-align:center;
	font-weight:normal;
	border-bottom:1px dotted #ccc;
	height:40px;
}

.tbl02 td {
	background:#fff;
	padding:10px 15px;
	word-break: break-all;
	border-bottom:1px dotted #ccc;
}

.tbl02 th.un,
.tbl02 td.un {
	border-bottom:none;
}


/*  tabulous
 *---------------------------------------------------------- */
/*  tabslet
 *---------------------------------------------------------- */
 .tabs {
	text-align:center;
  opacity:1.0;
}

.tabs ul,
.tabs div {
  opacity:1.0;
}

ul.horizontal {
	margin-bottom:40px;
	padding:10px 0;
	border-top:1px solid #1f1f1f;
	border-bottom:1px solid #1f1f1f;
  opacity:1.0;
  text-align: center;
}

ul.horizontal li {
	display:inline-block;
	margin:0 7px;
  opacity:1.0;
}

ul.horizontal li a {
	display:block;
	padding:7px 15px;
	text-align:center;
}

ul.horizontal li a:hover,
ul.horizontal li.active a {
	background:#e6b186;
	border-radius:0;
	color:#FFF;
}

@media screen and (max-width: 760px) {

ul.horizontal {
	margin-bottom:5%;
  padding:0;
  border: none;
}

ul.horizontal li {
  display:block;
  float: left;
  width: 50%;
	margin:0 7px;
  opacity:1.0;
  margin: 0;
  border-right: 1px solid #FFF;
  border-bottom: 1px solid #FFF;
}

ul.horizontal li:nth-child(2n) {
  border-right:none;
}

ul.horizontal li a {
	display:block;
	padding:12px 5px;
  text-align:center;
  background:#f9eee4;
  font-size: 12px;
}

ul.horizontal li a:hover,
ul.horizontal li.active a {
	background:#e6b186;
	border-radius:0;
	color:#FFF;
}

}

table,
ul ul,
ol ol,
td ul,
td p,
td dt,
td dd { font-size: 100%; }

select,
input,
textarea,
button { font-size: 99%; }


/************************************************

	Pager

************************************************/

.pager {
  clear: both;
  position: relative;
  margin: 5%;
}

.pager .next{
  text-align: center;
  position: absolute;
  bottom:-30px;
  right:0;
}

.pager .next a{
  display: block;
  background: #e1e2e0;
  padding: 6px 15px;
}

.pager .before,
.pager .prev{
  text-align: center;
  position: absolute;
  bottom:-30px;
  left:0;
}

.pager .before a,
.pager .prev a{
  display: block;
  background: #e1e2e0;
  padding: 6px 15px;
}

@media screen and (min-width: 761px) {

.pager {
  margin: 0;
}

.pager li{
  width: 200px;
}

.pager .next{
  right:250px;
}

.pager .before,
.pager .prev {
  left:250px;
}


}


/*  Main
 *---------------------------------------------------------- */
 #main{
	 width:700px;
	 margin:0;
	 float:left;
 }
/*  Side
 *---------------------------------------------------------- */
 #side{
	 margin:0;
	 float:right;
 }


/* ----------------------------------------------------------

 Clear Fix

---------------------------------------------------------- */
.clearfix:after {
  content: ".";
  display: block;
  height: 0;
  clear: both;
  visibility: hidden;
}

.clearfix { display: inline-table; }

/* Hides from IE-mac \*/
* html .clearfix { height: 1%; }
.clearfix {
	display: block;
}

@media print, screen and (min-width:741px){
  .sp {
	display:none!important;
}
}

@media (min-width:741px) and (max-width:1050px) {
	html { height: 100%; }
	body {
		width: 165%; /* transform: scale の倍率の逆数を設定する事 */
		   -moz-transform-origin: 0 0;
		-webkit-transform-origin: 0 0;
		        transform-origin: 0 0;
		   -moz-transform: scale(0.606);
		-webkit-transform: scale(0.606);
		        transform: scale(0.606);
						overflow-x : hidden;
	}

#topcontrol{
	display:none;
}

  body.top{
    overflow-x: hidden;
  }

}



@media screen and (max-width:740px){
/************************************************

	Font Style

************************************************/

body{
	overflow-x : hidden;
	overflow-y : auto;
}

html{
	overflow-x : hidden;
overflow-y : auto;
}

h1,h2,h3,h4,h5,h6{
	line-height:1.0;
}

a {
 text-decoration:none;
}

.txt_s{
	font-size:9px;
	margin:0 0 0 5px;
	color:#808080;
	}


/* ---------------------------
	font size
--------------------------- */
body {
	font-size: 13px;
}

.pan{
	display:none;
}

/************************************************

	Layout

************************************************/

body {
	position:relative;
}

#wrapper{
    position:relative;
    width: 100%;
    overflow:hidden;
    z-index:1;
}
#wrapper #wrapInner{position: relative;}
#wrapper.open{position:fixed;}

#contents {
	background:url(../img/cmn/bg.jpg) repeat;
}


/************************************************

	Contents

************************************************/

#container {
	width:100%;
	padding:0px;
}


.pc {
	display:none!important;
}




/************************************************

	Main

************************************************/

.main,
.index_contents {
	width: auto!important;
	padding:15px 10px;
}

.main img {
	width:100%;
}

.anchor, .anchor2, .anchor3 {
    margin-bottom:20px;
}

.anchor li a, .anchor2 li a, .anchor3 li a {
    background: #fff none repeat scroll 0 0;
    border: 2px solid #d987ad;
    box-sizing: border-box;
    color: #d987ad;
    display: block;
    font-size: 1.1em;
    font-weight: bold;
    margin: 0 auto 5px;
    padding: 10px 0;
    text-align: center;
    text-decoration: none;
    width: 100%;
}

/************************************************

	Side

************************************************/

.side {

}


/**************************************

Common Accordion

**************************************/

dl.accordion {
	font-size:100%;
}
dl.accordion dt {
	background: -moz-linear-gradient(top, #FFF, #E6E6E6);
	background: -webkit-gradient(linear, left top, left bottom, from(#FFF), to(#E6E6E6));
	border-top:1px solid #B1B1B1;
	border-bottom:1px solid #FFF;
	position:relative;
	padding:8px 20px 8px 5px;
}
dl.accordion dt:after {
    border-right: 2px solid #B5A43A;
    border-top: 2px solid #B5A43A;
	-moz-transform: rotate(135deg);
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
	content: "";
    display: block;
    height: 4px;
    right: 8px;
    margin-top: -5px;
    position: absolute;
    top: 50%;
    width: 4px;
}
dl.accordion dt.open:after {
    border-right: 2px solid #B5A43A;
    border-top: 2px solid #B5A43A;
	-moz-transform: rotate(-45deg);
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	content: "";
    display: block;
    height: 4px;
    right: 8px;
    margin-top: -3px;
    position: absolute;
    top: 50%;
    width: 4px;
}

dl.accordion dd {
	display:none;
	padding:10px;
	line-height:1.5;
	background:#FFF;
}

dl.accordion dd p {
	padding:10px 0px 0px 0px;
}


#topcontrol {
	z-index:10000;
}

.gsc-result .gs-title {
    height: 1.4em!important;
}

/************************************************

	Clear

************************************************/

.mnav .box,
.mnav2 .box,
.snav ul,
.contact .unit-a,
.btm
{
	zoom:1;
}

.mnav .box:after,
.mnav2 .box:after,
.snav ul:after,
.contact .unit-a:after,
.btm:after
{
	content:''; display:block; clear:both; overflow:hidden;
}

}

